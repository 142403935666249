import Inputmask from 'inputmask'
import WebFont from 'webfontloader'
import 'jquery-ui/themes/base/core.css'
import 'jquery-ui/themes/base/theme.css'
import 'jquery-ui/themes/base/datepicker.css'
const $ = require('jquery')
// import $ from 'jquery'
globalThis.$ = globalThis.jQuery = $
require('jquery-ui/ui/widgets/datepicker')
require('jquery-ui/ui/i18n/datepicker-ru')

$(function () {
  $.datepicker.setDefaults($.datepicker.regional['ru'])
  $('#birthday').datepicker({ dateFormat: 'dd.mm.yyyy' })
  Inputmask({
    mask: '99.99.9999',
    regex: '^s*(3[01]|[12][0-9]|0?[1-9]).(1[012]|0?[1-9]).((?:19|20)d{2})s*$',
  }).mask('#birthday')
  Inputmask({ mask: '@*{1,100}' }).mask('#telegram')
  Inputmask({ mask: '+7(999)999-99-99' }).mask('#phone')
  // Inputmask({ regex: '^[А-Яа-я]+$' }).mask('#first-name, #last-name, #sur-name')

  $('#email-form1').on('submit', function (event) {
    event.preventDefault()
    $.ajax({
      type: 'POST',
      url: 'https://member.civsoc.net/donttrytofoolmearound/',
      data: $(this).serializeArray(),
      beforeSend: function () {
        $('#send-button').attr('disabled', 'disabled')
        $('#send-button').attr('value', 'Пожалуйста, подождите')
      },
      success: function (response) {
        $('#email-form1').hide()
        $('.w-form-done').show()
        console.log('Заявка отправлена успешно!')
        console.log(response)
      },
      error: function (response) {
        $('.w-form-fail1').show()
        console.log(response)
      },
    })
  })

  $('#current-year').text(new Date().getFullYear())

  const headerParent = $('#header-parrent')
  const hideHeaderTrigger = $('#click-to-hide-context-menu')

  function hideContext() {
    hideHeaderTrigger.trigger('click')
  }

  let headerTimer
  headerParent.on('mouseleave', function () {
    if (!headerTimer) headerTimer = setTimeout(hideContext, 3000)
  })
  headerParent.on('mouseover', function () {
    clearTimeout(headerTimer)
  })

  WebFont.load({
    google: {
      families: ['PT Sans:400,400italic,700,700italic'],
    },
  })
})
